<template>
     <div>
          <el-dialog v-model="visiOrder" width="790px" lock-sroll="false">
               <div class="group1 flex-col">
                    <div class="main1 flex-col">
                         <div class="mod1 flex-row">
                              <span class="txt1">{{L['查看订单']}}</span>
                              <div class="label1 el-icon-close" @click="visiOrder = false"></div>
                         </div>
                    </div>
                    <div class="main22 flex-col">
                         <div class="mod2 flex-col">
                              <div class="group2 flex-row">
                                   <div class="word0"></div>
                                   <div class="word1">{{L['订单号']}}</div>
                                   <div class="word2">{{L['采购单号']}}</div>
                                   <div class="word3">{{L['供应商']}}</div>
                                   <div class="word4">{{L['订单状态']}}</div>
                                   <div class="word5">{{L['操作']}}</div>
                              </div>
                              <div class="layer43" v-if="orderList.length">
                                   <div class="group39 flex-row" v-for="(item, index) in orderList" :key="index">
                                        <span class="word6">{{ index+ 1}}</span>
                                        <span class="txt2" :title="item.orderSn">{{ item.orderSn }}</span>
                                        <span class="info1" :title="item.purchaseSn">{{
                                             item.purchaseSn ?
                                                  item.purchaseSn : '--'
                                        }}</span>
                                        <span class="word7">{{ item.storeName }}</span>
                                        <span class="txt3">{{ item.orderStateValue }}</span>
                                        <button class="bd2 flex-col">
                                             <a :href="`/member/order/info?orderSn=${item.orderSn}&memberId=${applyMember}&opState=${opState}`"
                                                  target="_blank">
                                                  <span class="word8">{{L['查看详情']}}</span>
                                             </a>
                                        </button>
                                   </div>
                              </div>
                              <div class="order_empty" v-if="!orderList.length">
                                   <p>{{L['暂无数据']}}</p>
                              </div>
                         </div>
                    </div>
               </div>
          </el-dialog>


          <el-dialog v-model="visiReset" width="610px" lock-sroll="false">
               <div class="group1 flex-col">
                    <div class="main11 flex-col">
                         <div class="mod1 flex_row_between_center">
                              <span class="txt1">{{L['重置密码']}}</span>
                              <div class="label1 el-icon-close" @click="visiReset = false"></div>
                         </div>
                    </div>
                    <div class="main24">
                         <table id="popTable">
                              <tr class="row3">
                                   <td class="td_title"><span><i>*</i>{{L['新密码：']}}</span></td>
                                   <td>
                                        <el-input type="password" :placeholder="L['请输入密码']" v-model="subAccountForm.passw"
                                             @input="watchContent('passw')" @blur="checkDo('passw')"
                                             autocomplete="new-password">
                                        </el-input>
                                   </td>
                                   <td>
                                        <span v-show="formCheckTip.passw != true" class="message">{{
                                             formCheckTip.passw
                                        }}</span>
                                   </td>
                              </tr>
                              <tr>
                                   <td></td>
                                   <td class="tip">{{L['请设置6-20位字母、数字或符号组成的密码']}}</td>
                              </tr>
                              <tr class="row4">
                                   <td class="td_title"><i>*</i><span>{{L['确认密码：']}}</span></td>
                                   <td>
                                        <el-input type="password" :placeholder="L['请再次输入密码']"
                                             v-model="subAccountForm.passwCon" @input="watchContent(passwCon)"
                                             @blur="checkDo('passwCon')">
                                        </el-input>
                                   </td>
                                   <td>
                                        <span v-show="formCheckTip.passwCon != true" class="message">{{
                                             formCheckTip.passwCon
                                        }}</span>
                                   </td>
                              </tr>
                              <tr>
                                   <td></td>
                                   <td class="tip">{{L['请设置6-20位字母、数字或符号组成的密码']}}</td>
                              </tr>
                              <tr>
                                   <td></td>
                                   <td class="confirmCreate">
                                        <div class="confirmBtn" @click="createBtn('reset')">{{L['确认']}}</div>
                                   </td>
                              </tr>
                         </table>
                    </div>
               </div>
          </el-dialog>


          <el-dialog v-model="visiEdit" width="610px" lock-sroll="false">
               <div class="group1 flex-col">
                    <div class="main11 flex-col">
                         <div class="mod1 flex-row">
                              <span class="txt1">{{L['编辑']}}</span>
                              <div class="label1 el-icon-close" @click="visiEdit = false"></div>
                         </div>
                    </div>
                    <div class="main24">
                         <table id="popTable">
                              <tr class="row1">
                                   <td class="td_title"><i>*</i><span>{{L['手机号码：']}}</span></td>
                                   <td>
                                        <el-input type="number" :placeholder="L['请输入手机号码']" v-model="subAccountForm.phone"
                                             @input="watchContent('phone')" @blur="checkDo('phone')">
                                        </el-input>
                                   </td>
                                   <td>
                                        <span v-show="formCheckTip.phone != true" class="message">{{
                                             formCheckTip.phone
                                        }}</span>
                                   </td>
                              </tr>
                              <tr class="row5">
                                   <td class="td_title"><span>{{L['名称：']}}</span></td>
                                   <td>
                                        <el-input :placeholder="L['请输入账号名称']" v-model="subAccountForm.name"
                                             @input="watchContent(2)" maxlength="20" show-word-limit>
                                        </el-input>
                                   </td>
                              </tr>
                              <tr>
                                   <td></td>
                                   <td class="confirmCreate">
                                        <div class="confirmBtn" @click="createBtn('edit')">{{L['确认']}}</div>
                                   </td>
                              </tr>
                         </table>
                    </div>
               </div>
          </el-dialog>
     </div>
</template>

<script>
import { ref, getCurrentInstance, reactive, onMounted, watch, toRefs } from 'vue';
import { ElMessage } from 'element-plus';
export default {
     props: ['memberId'],
     setup(props, { emit }) {
          const { proxy } = getCurrentInstance()
          const L = proxy.$getCurLanguage()
          const visiOrder = ref(false)
          const visiReset = ref(false)
          const visiEdit = ref(false)
          const applyMember = ref('')
          const applySn = ref('')
          const subAccountForm = reactive({
               phone: '',
               passw: '',
               passwCon: '',
               name: ''
          })
          const formCheckTip = reactive({
               phone: '',
               passw: '',
               passwCon: '',
          })
          const memberId = ref(null)
          const orderList = ref([])
          const opState = ref('ope')
          //确认end
          const watchContent = (type) => {
               formCheckTip[type] = ''
          }

          watch(() => props.memberId, () => {
               memberId.value = props.memberId
          })

          const orderInfo = reactive({
               current: 1,
               page: {}
          })

          const checkDo = (label) => {
               switch (label) {
                    case 'phone': {
                         formCheckTip.phone = proxy.$checkPhone(subAccountForm.phone)
                         break
                    }
                    case 'passw': {
                         formCheckTip.passw = proxy.$checkPwd(subAccountForm.passw)
                         break
                    }
                    case 'passwCon': {
                         if (!subAccountForm.passwCon) {
                              formCheckTip.passwCon = L['请再次输入密码']
                         } else if (subAccountForm.passwCon.trim() != subAccountForm.passw.trim()) {
                              formCheckTip.passwCon = L['确认密码不一致']
                         } else {
                              formCheckTip.passwCon = true
                         }
                         break
                    }

               }
          }

          const createBtn = (type) => {
               switch (type) {
                    case 'edit': {
                         if (proxy.$checkPhone(subAccountForm.phone) != true) {
                              formCheckTip.phone = proxy.$checkPhone(subAccountForm.phone)
                              return
                         }

                         let param = {
                              memberId: memberId.value,
                              memberMobile: subAccountForm.phone.trim(),
                              designerName: subAccountForm.name.trim()
                         }
                         proxy.$post('v3/member/front/enterprise/designer/update', param).then(res => {
                              if (res.state == 200) {
                                   ElMessage.success(res.msg)
                                   visiEdit.value = false
                                   subAccountForm.phone = ''
                                   subAccountForm.name = ''
                                   emit('refresh')
                              } else {
                                   ElMessage(res.msg)
                              }
                         })

                         break
                    }

                    case 'reset': {
                         if (proxy.$checkPwd(subAccountForm.passw) != true) {
                              formCheckTip.passw = proxy.$checkPwd(subAccountForm.passw)
                              return
                         }

                         if (!subAccountForm.passwCon) {
                              formCheckTip.passwCon = L['请再次输入密码']
                              return
                         } else if (subAccountForm.passwCon.trim() != subAccountForm.passw.trim()) {
                              formCheckTip.passwCon = L['确认密码不一致']
                              return
                         }

                         let param = {
                              memberId: memberId.value,
                              newPassword: subAccountForm.passw.trim(),
                              newPasswordCfm: subAccountForm.passwCon.trim()
                         }

                         proxy.$post('v3/member/front/enterprise/designer/resetPassword', param).then(res => {
                              if (res.state == 200) {
                                   ElMessage.success(res.msg)
                                   visiReset.value = false
                                   subAccountForm.passw = ''
                                   subAccountForm.passwCon = ''
                                   emit('refresh')
                              } else {
                                   ElMessage(res.msg)
                              }
                         })
                         break
                    }
               }
          }

          const handleCurrentChange = (e) => {
               orderInfo.current = Math.floor(e);
               getSubOrder()
          }

          const getSubOrder = () => {
               let param = {};
               if (memberId.value) {
                    param.memberId = memberId.value;
               }
               if (applySn.value) {
                    param.applySn = applySn.value;
               }
               proxy.$get('v3/business/front/orderInfo/orderList', param).then(res => {
                    if (res.state == 200) {
                         orderList.value = res.data
                         orderInfo.page = res.data.pagination
                    }
               })
          }


          watch(visiReset, () => {
               if (!visiReset.value) {
                    subAccountForm.passw = ''
                    subAccountForm.passwCon = ''
                    formCheckTip.passwCon = ''
                    formCheckTip.passw = ''
               }
          })

          return {
               visiOrder,
               visiReset,
               subAccountForm,
               formCheckTip,
               visiEdit,
               checkDo,
               watchContent,
               createBtn,
               getSubOrder,
               orderList,
               memberId,
               ...toRefs(orderInfo),
               handleCurrentChange,
               applyMember,
               applySn,
               opState,
               L
          }
     }
}
</script>
<style lang="scss">
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
     -webkit-appearance: none;
}



.group1 {

     height: 475px;

     .main1,
     .main11 {
          height: 46px;
          border-radius: 4px 4px 0 0;
          background-color: rgba(248, 248, 248, 1);
          width: 100%;
          justify-content: center;
          align-items: flex-start;
          padding-left: 20px;

          .mod1 {
               z-index: auto;
               height: 16px;
               justify-content: space-between;
               width: 100%;
               padding-right: 15px;

               .txt1 {
                    display: block;
                    color: rgba(51, 51, 51, 1);
                    font-size: 14px;
                    text-align: left;
                    margin-top: 1px;
               }

               .label1 {
                    font-size: 16px;
                    font-weight: bold;
                    cursor: pointer;
               }
          }
     }

     .main22 {
          z-index: 11;

          border-radius: 3px 3px 0 0;
          align-self: center;
          margin-top: 20px;
          width: 747px;
          justify-content: flex-start;

          .mod2 {
               z-index: auto;



               .layer43 {
                    margin-top: 10px;
                    height: 300px;
                    overflow: auto;
               }

               .order_empty {
                    display: flex;
                    justify-content: center;
                    margin-top: 100px;
               }


               .group2 {
                    height: 40px;
                    border-radius: 3px 3px 0 0;
                    background: #eee;
                    padding-left: 10px;

                    .word0 {
                         width: 56px;
                         height: 40px;
                    }

                    .word1 {

                         width: 126px;
                         height: 40px;
                         text-align: center;
                         font-size: 14px;
                         line-height: 40px;

                    }

                    .word2 {

                         width: 156px;
                         height: 40px;
                         color: rgba(102, 102, 102, 1);
                         font-size: 14px;
                         text-align: center;
                         line-height: 40px;
                    }

                    .word3 {

                         width: 120px;
                         height: 40px;
                         color: rgba(102, 102, 102, 1);
                         font-size: 14px;
                         text-align: center;
                         line-height: 40px;

                    }

                    .word4 {
                         width: 120px;
                         height: 40px;
                         color: rgba(102, 102, 102, 1);
                         font-size: 14px;
                         text-align: center;
                         line-height: 40px;
                    }

                    .word5 {
                         width: 140px;
                         height: 40px;
                         color: rgba(102, 102, 102, 1);
                         font-size: 14px;
                         text-align: center;
                         line-height: 40px;
                    }


               }

               .group39 {
                    height: 53px;
                    padding-left: 10px;
                    align-items: center;

                    .word6 {
                         width: 56px;
                         height: 53px;
                         display: block;

                         color: rgba(102, 102, 102, 1);
                         font-size: 14px;
                         text-align: center;
                         line-height: 53px;
                    }

                    .txt2 {

                         width: 126px;
                         height: 53px;
                         display: block;

                         color: rgba(102, 102, 102, 1);
                         font-size: 14px;
                         text-align: center;
                         line-height: 53px;
                    }

                    .info1 {
                         width: 156px;
                         height: 18px;
                         color: rgba(102, 102, 102, 1);
                         font-size: 14px;
                         text-align: center;
                         overflow: hidden;
                         text-overflow: ellipsis;
                         white-space: nowrap;
                    }

                    .word7 {

                         width: 120px;
                         height: 53px;
                         color: rgba(102, 102, 102, 1);
                         font-size: 14px;
                         line-height: 53px;
                         text-align: center;

                    }

                    .txt3 {

                         width: 120px;
                         height: 53px;
                         display: block;
                         color: rgba(102, 102, 102, 1);
                         font-size: 14px;
                         text-align: center;
                         line-height: 53px;
                    }

                    .bd2 {
                         height: 53px;
                         border-radius: 2px;
                         border: none;
                         background-color: rgba(255, 255, 255, 1);
                         width: 140px;
                         justify-content: center;
                         align-items: center;

                         .word8 {
                              width: 95px;
                              height: 30px;
                              display: block;
                              color: rgba(40, 95, 222, 1);
                              border: 1px solid rgba(40, 95, 222, 1);
                              font-size: 14px;
                              text-align: center;
                              line-height: 30px;
                         }
                    }

               }
          }
     }

     .main24 {
          padding-left: 30px;
     }

     .confirmBtn {
          width: 100px;
          height: 38px;
          background: $colorMain;
          text-align: center;
          line-height: 38px;
          color: #fff;
          margin-top: 30px;
          cursor: pointer;
     }


}

#popTable {

     border-spacing: 10px;

     .td_title {
          text-align: right;
          font-size: 14px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #666666;
     }

     .tip {
          color: #C0C4CC;
     }

     .el-input {
          width: 338px;
     }

     .el-input__inner {
          width: 338px;
          font-size: 13px;
          padding-right: 0;
          border-radius: 2px;
          padding-bottom: 1px;

     }

     i {
          color: $colorPrice;
          font-style: normal;
     }

     .message {
          font-size: 12px;
          line-height: 2;
          padding-top: 4px;
          color: $colorPrice;
          font-family: Microsoft YaHei;
     }

     td {
          max-width: 338px;

          &.title {
               padding-top: 9px;
          }
     }
}
</style>