<template>
  <div class="sld_apply_list">
    <!-- <MemberTitle :memberTitle="L['采购申请']"></MemberTitle> -->
    <div class="container">
      <h3>{{ L['采购申请'] }}</h3>
      <div class="box2 flex-row">
        <span class="word23" :class="{ active: tabIndex == 0 }" @click="changeTab(0)">{{L['全部']}}</span>
        <span class="word23" :class="{ active: tabIndex == 1 }" @click="changeTab(1)">{{L['待审核']}}</span>
        <span class="word23" :class="{ active: tabIndex == 2 }" @click="changeTab(2)">{{L['采购中']}}</span>
        <span class="word23" :class="{ active: tabIndex == 3 }" @click="changeTab(3)">{{L['已完成']}}</span>
        <span class="word23" :class="{ active: tabIndex == 4 }" @click="changeTab(4)">{{L['已拒绝']}}</span>
        <div class="box3 flex-col">
          <div class="layer13 flex-row">
            <el-input maxlength="25" v-model="selectValue" :placeholder="L['请输入申请单号/项目名称']" @clear="getList" clearable />
            <span class="line1"></span>
            <i class="iconfont iconsousuo- label8" @click="getList"></i>
          </div>
        </div>
      </div>
      <div class="box10 flex-col">
        <div class="bd3 flex-row">
          <span class="word33">{{L['申请单号']}}</span>
          <span class="word33 word34">{{L['项目名称']}}</span>
          <span class="word33 info17">{{L['申请时间']}}</span>
          <span class="word33 word35">{{L['状态']}}</span>
          <span class="word33 word36">{{L['拒绝理由']}}</span>
          <span class="word33 info18">{{L['操作']}}</span>
        </div>
      </div>
      <template v-if="orderList.length > 0">
        <template v-for="item in orderList" :key="item.applyId">
          <div class="box11 flex-row">
            <span class="word33">{{ item.applySn }}</span>
            <span v-if="item.projectName && item.projectName.length > 14" class="word33 word34"
              :title="item.projectName">{{ item.projectName }}</span>
            <span v-else class="word33 word34">{{ item.projectName ? item.projectName : '--' }}</span>
            <span class="word33 info17">{{ item.createTime }}</span>
            <span class="word33 word35">{{ item.stateValue }}</span>
            <span v-if="item.refuseReason && item.refuseReason.length > 13" class="word33 word36"
              :title="item.refuseReason">{{ item.refuseReason }}</span>
            <span v-else class="word33 word36">{{ item.refuseReason ? item.refuseReason : '--' }}</span>
            <span class="info18 flex-row">
              <div @click="info(item.applyId)">{{L['查看详情']}}</div>
              <div v-if="item.state == 2 || item.state == 3" @click="subAc(item.memberId, item.applySn)">{{L['查看订单']}}</div>
            </span>
          </div>
        </template>
      </template>
      <template v-else>
        <SldCommonEmpty totalWidth="1003" :tip="L['暂无采购申请数据']" />
      </template>
    </div>
    <el-pagination @current-change="handleCurrentChange" :currentPage="pageData.current" :page-size="pageData.pageSize"
      layout="prev, pager, next, jumper" :total="pageData.total" :hide-on-single-page="false"
      class="flex_row_end_center" v-if="orderList.length > 0"></el-pagination>
    <subAcDialog ref="dialog"></subAcDialog>
  </div>
</template>

<script>
import { reactive, ref, getCurrentInstance, onMounted } from "vue";
import MemberTitle from "../../components/MemberTitle";
import { useRouter } from "vue-router";
import { ElMessageBox } from 'element-plus';
import SldCommonEmpty from '@/components/SldCommonEmpty';
import subAcDialog from './subAccount/subAcDialog';
export default {
  name: "memberDesignerApply",
  components: {
    MemberTitle,
    SldCommonEmpty,
    subAcDialog,
  },

  setup() {
    const { proxy } = getCurrentInstance();
    const L = proxy.$getCurLanguage();
    const router = useRouter();
    const tabIndex = ref(0);
    const selectValue = ref("");
    const orderList = ref([]);
    const pageData = reactive({
      current: 1,
      pageSize: 10,
      total: 0
    })

    const changeTab = (index) => {
      //切换导航栏
      if (tabIndex.value != index) {
        tabIndex.value = index;
        pageData.current = 1;
        pageData.total = 0;
        getList();
      }
    };

    const getList = () => { //获取采购申请列表
      let param = {
        current: pageData.current, //当前页面位置
        pageSize: pageData.pageSize, //分页大小
      }
      if (selectValue.value) { //关键词(申请单号/项目名称)
        param.keyword = selectValue.value
      }
      if (tabIndex.value > 0) { //状态
        param.state = tabIndex.value
      }
      proxy
        .$get('v3/member/front/member/purchase/apply/list', param)
        .then(res => {
          orderList.value = res.data.list;
          pageData.total = res.data.pagination.total;
        })
    };

    const info = (id) => {
      router.push({
        path: `/member/applyInfo`,
        query: {
          id: id,
        },
      });
    };

    const delet = (id) => {
      ElMessageBox.confirm(L['确定删除该条采购申请?'], L['提示'], {
        confirmButtonText: L['确定'],
        cancelButtonText: L['取消'],
        type: "warning",
      }).then(() => {

      })
    };

    //页数改变
    const handleCurrentChange = e => {
      pageData.current = Math.floor(e);
      getList();
    };

    const subAc = (memberId, applySn) => {
      proxy.$refs.dialog.applyMember = memberId;
      proxy.$refs.dialog.applySn = applySn;
      proxy.$refs.dialog.getSubOrder();
      proxy.$refs.dialog.opState = 'nope'
      proxy.$refs.dialog.visiOrder = true;
    };

    onMounted(() => {
      getList();
    });

    return {
      L,
      pageData,
      changeTab,
      getList,
      tabIndex,
      selectValue,
      orderList,
      info,
      delet,
      handleCurrentChange,
      subAc,
    };
  },


};
</script>

<style lang="scss" scoped>
@import "../../style/designerApply.scss";
</style>
<style lang="scss">
.sld_apply_list {
  .layer13 {
    .el-input {
      display: flex;
      height: 30px;
    }

    .el-input__inner {
      width: 263px;
      height: 30px;
      border: none;
    }

    .el-input__clear {
      position: absolute;
      right: 5px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .el-pagination {
    background-color: #FFFFFF;
    padding-right: 20px;
    padding-bottom: 10px;
  }

  .el-dialog__header {
    display: none;
  }

  .el-dialog__body {
    padding: 0;
  }
}

.designer_application_orderList {
  .el-dialog__header {
    display: none;
  }

  .el-dialog__body {
    padding: 0;
  }
}
</style>